import { StxRequestType, StxStatusRequestIncoming, StxStatusRequestOutgoing } from '@ps-monorepo/api-constants';

/* eslint-disable no-shadow, no-unused-vars */
export enum StatusDisplayNames {
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  POSTED = 'POSTED',
  RECEIVED = 'RECEIVED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED'
}
/* eslint-enable no-shadow, no-unused-vars */

export const STXStatusIcons = new Map([
  [StatusDisplayNames.PROCESSING, 'inprogress'],
  [StatusDisplayNames.SCHEDULED, 'calendar'],
  [StatusDisplayNames.REJECTED, 'x-alt'],
  [StatusDisplayNames.COMPLETED, 'checkmark-alt'],
  [StatusDisplayNames.POSTED, 'inprogress'],
  [StatusDisplayNames.RECEIVED, 'inprogress'],
  [StatusDisplayNames.ERROR, 'exclamation'],
  [StatusDisplayNames.PENDING, ''],
  [StatusDisplayNames.ASSIGNED, 'user']
]);

export const STXIconColors = new Map([
  // ['OPEN', 'orange'], // TODO: There is no OPEN status anywhere else?
  [StatusDisplayNames.PROCESSING, ''],
  [StatusDisplayNames.SCHEDULED, ''],
  [StatusDisplayNames.REJECTED, 'red'],
  [StatusDisplayNames.COMPLETED, 'green'],
  [StatusDisplayNames.POSTED, ''],
  [StatusDisplayNames.RECEIVED, ''],
  [StatusDisplayNames.ERROR, 'orange'],
  [StatusDisplayNames.PENDING, ''],
  [StatusDisplayNames.ASSIGNED, 'blue']
]);

export const STXIncomingStatusDisplayNames = new Map([
  [StxStatusRequestIncoming.pending, StatusDisplayNames.PENDING],
  [StxStatusRequestIncoming.assigned, StatusDisplayNames.ASSIGNED],
  [StxStatusRequestIncoming.scheduled, StatusDisplayNames.SCHEDULED],
  [StxStatusRequestIncoming.processing, StatusDisplayNames.PROCESSING],
  [StxStatusRequestIncoming.error, StatusDisplayNames.ERROR],
  [StxStatusRequestIncoming.completed, StatusDisplayNames.COMPLETED],
  [StxStatusRequestIncoming.rejected, StatusDisplayNames.REJECTED],
  [StxStatusRequestIncoming.generatingResponse, StatusDisplayNames.PROCESSING],
  [StxStatusRequestIncoming.validating, StatusDisplayNames.PROCESSING],
  [StxStatusRequestIncoming.waitForDispatch, StatusDisplayNames.PROCESSING],
  [StxStatusRequestIncoming.waitForAck, StatusDisplayNames.PROCESSING],
  [StxStatusRequestIncoming.internalError, StatusDisplayNames.ERROR],
  [StxStatusRequestIncoming.systemError, StatusDisplayNames.ERROR],
  [StxStatusRequestIncoming.generatingReject, StatusDisplayNames.REJECTED],
  [StxStatusRequestIncoming.waitForDispatchReject, StatusDisplayNames.REJECTED],
  [StxStatusRequestIncoming.waitForAckReject, StatusDisplayNames.REJECTED]
]);

export const STXOutgoingStatusDisplayNames = new Map([
  [StxStatusRequestOutgoing.processing, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.scheduled, StatusDisplayNames.SCHEDULED],
  [StxStatusRequestOutgoing.posted, StatusDisplayNames.POSTED],
  [StxStatusRequestOutgoing.rejected, StatusDisplayNames.REJECTED],
  [StxStatusRequestOutgoing.received, StatusDisplayNames.RECEIVED],
  [StxStatusRequestOutgoing.completed, StatusDisplayNames.COMPLETED],
  [StxStatusRequestOutgoing.waitForDispatch, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.pending, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.seen, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.generatingPdf, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.attachingToSis, StatusDisplayNames.PROCESSING],
  [StxStatusRequestOutgoing.systemError, StatusDisplayNames.REJECTED],
  // Originally mapped to REJECTED, but that does not make sense since it hasn't reached the state
  // system yet
  [StxStatusRequestOutgoing.internalError, StatusDisplayNames.ERROR]
]);

export const STXRequestTypes = new Map([
  [StxRequestType.interDistrict, 'Interdistrict (I00)'],
  [StxRequestType.secondary, 'Secondary to Postsecondary (S00)'],
  [StxRequestType.postSecondary, 'Postsecondary (P00)']
]);
