import { UserAlertSeverity } from '@ps-monorepo/api-constants';
import { PdsMessageType } from 'powerschool-design-system/tool-kit';

// Alias PDS for brevity
export const pds = window.powerSchoolDesignSystemToolkit;

// PDS Message Service (Polymer)
export const pdsMessageService = pds.serviceInstances.getMessageService();

// PDS Grid Widget Service (React)
export const pdsGridWidgetService = pds.serviceInstances.getGridWidgetService();

/**
 * Convert user alert severity to PDS message type
 *
 * @param severity
 */
export function convertToPdsMessageType(severity): PdsMessageType {
  if (severity === UserAlertSeverity.ERROR) {
    return 'ERROR';
  }
  if (severity === UserAlertSeverity.WARN) {
    return 'WARNING';
  }
  if (severity === UserAlertSeverity.INFO) {
    return 'INFO';
  }
  console.warn(
    `Unsupported severity ${severity} received in convertToPdsMessageType function, returning INFO level`
  );
  return 'INFO';
}
