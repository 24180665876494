// eslint-disable-next-line import/no-extraneous-dependencies
import { Time as CommonUtilsTime } from 'common-utils/interfaces';

/**
 * Internally used type for now
 */
export class Time implements CommonUtilsTime {
  /**
   * Internal representation is a Date object
   *
   * @private
   */
  private date: Date;

  /**
   * Constructor takes a date
   *
   * @param date
   */
  // constructor(date) { this.date = date; }
  /* eslint-disable */
  constructor(date: Date);
  /* eslint-disable */
  constructor(hours: number, minutes: number);

  constructor(param1: Date | number, param2?: number) {
    if (param1 instanceof Date) {
      this.date = param1;
    } else {
      this.date = new Date();
      this.date.setHours(param1, param2, 0, 0);
    }
  }

  to24HourTime(): string { return `${this.date.getHours()}:${this.date.getMinutes()}`; }

  getHours(): number { return this.date.getHours(); }

  setHours(hours: number): void { this.date.setHours(hours); }

  setMinutes(minutes: number): void { this.date.setMinutes(minutes); }

  getMinutes(): number { return this.date.getMinutes(); }

  getTime(): number { return this.date.getTime(); }

  set(time: CommonUtilsTime): void { this.date.setTime(time.getTime()); }
}
