/**
 * Enum of constants used as IDs in student grid widget DOM
 */
/* eslint-disable no-shadow, no-unused-vars */
export enum StudentGridWidgetFieldIds {
  FIRST_NAME = 'first-name',
  MIDDLE_NAME = 'middle-name',
  LAST_NAME = 'last-name',
  STATE_STUDENT_ID = 'state-student-id',
  DOB = 'dob',
  GRADE_LEVEL = 'grade-level',
  GENDER = 'gender',
  SCHOOL_ENROLLED = 'school-enrolled'
}
