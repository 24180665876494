import React from 'react';

/**
 * Logged out written as functional component as it has no other members/properties/functions
 */
export function LoggedOut() {
  return (
    <main>
      <h5>
        You are now signed out of PowerSchool Continuous Compliance. You have not been
        signed out of any other applications. Closing this browser tab is recommended.
      </h5>
    </main>
  );
}
