import React from 'react';
import ReactDOM from 'react-dom';
import { Main } from './app/main';

// Render the top-level React component only after Polymer components have loaded.
// Note: In order to eliminate requiring the import of PDS into package.json, we refer to PDS via
//       powerSchoolDesignSystemToolkit in the window object. The definition for window in
//       types/global.d.ts and inclusion of the types folder in tsconfig.json allows the line below
//       to compile properly.
window.powerSchoolDesignSystemToolkit.polymerUtils.regComplete.subscribe(() => {
  ReactDOM.render(React.createElement(Main), document.querySelector('#main-app-container'));
});
