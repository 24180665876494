import React from 'react';

import './draw-loader.scss';

type Props = { size: 'small' | 'medium' | 'large' };

/**
 * Animated loader with config for different sizes (small, medium, large)
 */
export default function DrawLoader(props: Props) {
  const { size } = props;
  return (
    <div className="draw-loader-center">
      <pds-loader data-size={ size } />
    </div>
  );
}
